import { useMutation, useQueryClient } from "react-query";
import { apiClient } from "../apiClient"
import { FeedResponseData } from "../feed/Feed";
import { ContentResponse } from "../feed/api/ContentResponse";

interface useSendStoryReactionProps {
    storyId: string,
    storyDateId: string,
    isFavorite: boolean,
    newLikesNumber: number,
    pageNumber?: number;
}

export const useSendStoryReaction = ({ storyId, storyDateId, isFavorite, newLikesNumber, pageNumber }: useSendStoryReactionProps) => {

    const endpoint: string = `/usertimeline/${storyDateId}/${storyId}/${isFavorite ? 'dislike' : 'like'}`
    const queryClient = useQueryClient();

    async function postReaction() {
        try {
            await apiClient.post(endpoint);
        }
        catch (error) {
            throw error;
        }
    }

    return useMutation(postReaction, {
        onMutate: async () => {
            const feedQueryData: { pages: FeedResponseData[] } | undefined = queryClient.getQueryData('content');

            const singleStoryQueryData: ContentResponse | undefined = queryClient.getQueryData(`fetchStory${storyId}`);

            if (feedQueryData && pageNumber !== undefined) {
                const pageToUpdate = feedQueryData.pages[pageNumber];
                const storyToUpdateIndex = pageToUpdate.Stories.findIndex(story => story.Id === storyId);

                if (storyToUpdateIndex !== -1) {
                    const prevFeedStoryData = { ...pageToUpdate.Stories[storyToUpdateIndex] };

                    queryClient.setQueryData<{ pages: FeedResponseData[] } | undefined>('content', (data: { pages: FeedResponseData[] } | undefined) => {
                        if (data) {
                            const newFeedStoryData = { ...data };

                            if (newFeedStoryData.pages[pageNumber]) {
                                newFeedStoryData.pages[pageNumber].Stories[storyToUpdateIndex] = {
                                    ...prevFeedStoryData,
                                    Favorite: !isFavorite,
                                    Likes: newLikesNumber
                                };
                            }
                            return newFeedStoryData;
                        }
                        return data;
                    });

                    return { prevFeedStoryData };
                }
            }

            if (singleStoryQueryData) {
                await queryClient.cancelQueries(`fetchStory${storyId}`);
                const storyToUpdate = singleStoryQueryData.Id === storyId ? singleStoryQueryData : undefined;
                if (storyToUpdate) {
                    const prevSingleStoryData = { ...storyToUpdate };
                    queryClient.setQueryData<ContentResponse | undefined>(`fetchStory${storyId}`,
                        data => {
                            if (data) {
                                return {
                                    ...data,
                                    Favorite: !isFavorite,
                                    Likes: newLikesNumber,
                                }
                            }
                            return undefined;
                        });
                    return { prevSingleStoryData };
                }
            }

        },
        onError: (error, variables, context) => {
            if (context?.prevFeedStoryData) {
                queryClient.setQueryData<{ pages: FeedResponseData[] } | undefined>('content', (data: { pages: FeedResponseData[] } | undefined) => {
                    if (data && pageNumber !== undefined) {
                        const newFeedStoryData = { ...data };
                        const pageToUpdate = newFeedStoryData.pages[pageNumber];
                        const storyToUpdateIndex = pageToUpdate?.Stories.findIndex(story => story.Id === storyId);

                        if (storyToUpdateIndex !== -1) {
                            newFeedStoryData.pages[pageNumber].Stories[storyToUpdateIndex] = {
                                ...context.prevFeedStoryData
                            };
                        }
                        return newFeedStoryData;
                    }
                    return data;
                });
            }
            if (context?.prevSingleStoryData) {
                queryClient.setQueryData<ContentResponse | undefined>(`fetchStory${storyId}`,
                    data => {
                        if (data) {
                            return {
                                ...context.prevSingleStoryData,
                            }
                        }
                    })
            }
        }
    });
};