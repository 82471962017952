import styles from "./GroupDetailsHeader.module.css";
import MiniAvatar from "../../components/elements/MiniAvatar";
import { ReactComponent as ArrowBack } from "../../components/icons/ArrowBackIcon.svg";
import { ReactComponent as MoreIcon } from "../../components/icons/MoreIcon.svg";
import { Link } from "react-router-dom";

interface GroupDetailsHeaderProps {
  groupName: string;
  isOwner: boolean;
  avatar: string;
  membersCount: number;
}

function GroupDetailsHeader(props: GroupDetailsHeaderProps) {
  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        <Link to="/Community/Groups">
          <ArrowBack />
        </Link>
      </div>
      <div className={styles.center}>
        <div className={styles.avatar}>
          <MiniAvatar src={props.avatar} height="50px" />
        </div>
        <div className={styles.groupName}>{props.groupName}</div>
        <div className={styles.membersCount}>{`${props.membersCount}${
          props.membersCount === 1 ? " User" : " Users"
        }`}</div>
      </div>
      <div className={styles.icons}>{props.isOwner ? <MoreIcon /> : null}</div>
    </div>
  );
}

export default GroupDetailsHeader;
