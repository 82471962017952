import styles from "./MyProfile.module.css";
import { useUserPreferencesData } from "../customHooks/useUserPreferencesData";
import ProfileAvatarSelector from "../avatar/ProfileAvatarSelector";
import EditablePersonalData from "./EditablePersonalData";
import { Link } from "react-router-dom";
import { ReactComponent as LeaveIcon } from "../components/icons/LeaveIcon.svg";
import { msalInstance } from "../apiClient";

function MyProfile() {
  const { data: myPreferences } = useUserPreferencesData();

  function handleLogout(): void {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  return myPreferences ? (
    <div className={styles.profileContainer}>
      <div className={styles.header}>My Profile</div>
      <div className={styles.profilePicture}>
        <ProfileAvatarSelector
          currentPicSrc={myPreferences.Avatar}
          containerClassname="avatarSelector__container"
          imgContainerClassname="avatarSelector__imgContainer"
          uploadButtonClassname="avatarSelector__uploadButton"
        />
      </div>
      <div>
        <div className={styles.personalDataDiv}>
          <EditablePersonalData
            currentName={myPreferences.Name ?? ""}
            currentUserName={myPreferences.UserName}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Link to="/Preferences" className={styles.buttonLink}>
            <button className={styles.button}>
              Adjust language and favorite categories
            </button>
          </Link>
          <button className={styles.button} onClick={() => handleLogout()}>
            <LeaveIcon />
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default MyProfile;
