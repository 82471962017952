import { SetStateAction } from "react";
import "./EditMembershipPopUp.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { apiClient } from "../../apiClient";
import { useParams } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";

interface EditMembershipPopUpProps {
  memberId: string;
  memberName: string;
  trigger: boolean;
  showEditMembershipPopUp: React.Dispatch<SetStateAction<boolean>>;
}

function EditMembershipPopUp(props: EditMembershipPopUpProps) {
  const { groupId } = useParams();

  async function editGroupOwner(newOwnerId: string) {
    const body = { GroupOwner: newOwnerId };
    await apiClient.patch(`groups/${groupId}`, body);
  }

  const queryClient = useQueryClient();

  const {
    mutate: mutateGroupOwner,
    isLoading: isLoadingGroupOwnerChange,
    isError: isErrorGroupNameChange,
  } = useMutation("mutateGroupOwner", editGroupOwner, {
    onSuccess: () => {
      queryClient.invalidateQueries(`getGroup${groupId}`);
    },
  });

  async function removeContactFromGroup(idToRemove: string) {
    const body = { DeletedGroupMembers: [idToRemove] };
    await apiClient.post(`groups/${groupId}/members/bulk`, body);
  }

  const {
    mutate: removeContact,
    isLoading: isLoadingRemoveContact,
    isError: isErrorRemovingContact,
  } = useMutation("removeContact", removeContactFromGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(`getGroup${groupId}`);
    },
  });

  return (
    //TODO handle errors on UI
    props.trigger ? (
      <div className="editMembership">
        <div className="buttonsContainer">
          <div className="memberDetails">
            <p>{props.memberName}</p>
            <button
              className="cancelButton"
              onClick={() => props.showEditMembershipPopUp(false)}
            >
              <CancelIcon />
            </button>
          </div>
          <button onClick={() => mutateGroupOwner(props.memberId)}>
            {isLoadingGroupOwnerChange ? "loading..." : "Make a group owner"}
          </button>
          <button onClick={() => removeContact(props.memberId)}>
            {isLoadingRemoveContact ? "Loading..." : "Remove from the group"}
          </button>
        </div>
      </div>
    ) : null
  );
}

export default EditMembershipPopUp;
