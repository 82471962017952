import "./ProfileAvatarSelector.css";
import { useState, useRef, ChangeEvent } from "react";
import CropperModal from "./CropperModal";
import defaultProfilePicture from "../profile/defaultProfilePicture.jpg";

interface ProfileAvatarSelectorProps {
  currentPicSrc?: string;
  containerClassname: string;
  imgContainerClassname: string;
  uploadButtonClassname: string;
}

function ProfileAvatarSelector({
  currentPicSrc,
  containerClassname,
  imgContainerClassname,
  uploadButtonClassname,
}: ProfileAvatarSelectorProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [newAvatarSrc, setNewAvatarSrc] = useState<string>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddPic = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    } // clear the previous input value

    inputRef.current?.click();
  };

  const handleImgChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setNewAvatarSrc(URL.createObjectURL(event.target.files[0]));
      setModalOpen(true);
    }
  };

  return (
    <div className={containerClassname}>
      <input
        type="file"
        ref={inputRef}
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleImgChange}
        style={{ display: "none" }}
      />
      <CropperModal
        newAvatarSrc={newAvatarSrc}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        endpoint="userpreferences/avatar"
        invalidateQueryKey="getUserPreferences"
      />

      <div className={imgContainerClassname}>
        <img
          src={currentPicSrc ? currentPicSrc : defaultProfilePicture}
          alt="profilePicture"
        />
      </div>

      <button className={uploadButtonClassname} onClick={() => handleAddPic()}>
        Upload new picture
      </button>
    </div>
  );
}

export default ProfileAvatarSelector;
