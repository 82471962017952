import "./Contact.css";
import { ReactElement } from "react";
import MiniAvatar from "./MiniAvatar";

export interface ContactProps {
  Name: string;
  UserName?: string;
  Avatar?: string;
  children?: ReactElement;
  onClick?: () => void;
}

function Contact(props: ContactProps) {
  return (
    <div className="contact__div" onClick={props.onClick}>
      <MiniAvatar height="40px" src={props.Avatar} />
      <div className="contact__nameContainer">
        <div className="contact__name">{props.Name}</div>
        {props.UserName ? (
          <div className="contact__UserName">{`(@${props.UserName})`}</div>
        ) : null}
      </div>
      {props.children ? <div className="contact__children">{props.children}</div> : null}
    </div>
  );
}

export default Contact;
