import { useState } from "react";
import EditMembershipPopUp from "./EditMembershipPopUp";
import { MemberBasicInfoType } from "../../types/MemberBasicInfoType";
import { ReactComponent as MoreIcon } from "../../components/icons/MoreIcon.svg";
import Contact from "../../components/elements/Contact";

interface GroupMembersListProps {
  groupMembers: MemberBasicInfoType[];
  groupOwner: MemberBasicInfoType;
  isOwner: boolean;
}

function GroupMembersList(props: GroupMembersListProps) {
  const [memberToEdit, setMemberToEdit] = useState<{
    id: string;
    name: string;
  }>();
  const [editMembershipPopUp, showEditMembershipPopUp] =
    useState<boolean>(false);

  const handleMoreButtonClick = (memberId: string, memberName: string) => {
    setMemberToEdit({ id: memberId, name: memberName });
    showEditMembershipPopUp(true);
  };

  return (
    <>
      {props.groupMembers.map((member: MemberBasicInfoType) => (
        <Contact
          Name={member.name}
          UserName={member.userName}
          Avatar={member.avatar}
          key={member.id}
        >
          {props.groupOwner.id === member.id ? (
            <div>Admin</div>
          ) : props.isOwner ? (
            <MoreIcon
              height="16px"
              onClick={() => handleMoreButtonClick(member.id, member.name)}
            />
          ) : undefined}
        </Contact>
      ))}
      {memberToEdit && editMembershipPopUp ? (
        <EditMembershipPopUp
          memberId={memberToEdit.id}
          memberName={memberToEdit.name}
          trigger={editMembershipPopUp}
          showEditMembershipPopUp={showEditMembershipPopUp}
        />
      ) : null}
    </>
  );
}

export default GroupMembersList;
