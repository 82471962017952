import MiniAvatar from "../../components/elements/MiniAvatar";
import styles from "./GroupInfoButton.module.css";

export interface GroupInfoButtonProps {
  groupName: string;
  avatar?: string;
}

function GroupInfoButton(props: GroupInfoButtonProps) {
  return (
    <button className={styles.div}>
      <MiniAvatar height="40px" src={props.avatar} />
      <div className={styles.groupName}>{props.groupName}</div>
    </button>
  );
}

export default GroupInfoButton;
