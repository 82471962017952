import './CropperModal.css';
import { Modal, Slider } from "@mui/material";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useMutation, useQueryClient } from 'react-query';
import { apiClient } from '../apiClient';
import { CircularProgress } from '@mui/material';

interface CropperModalProps {
    newAvatarSrc?: string,
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    endpoint: string,
    invalidateQueryKey: string;
}

function CropperModal(props: CropperModalProps) {

    const [slideValue, setSlideValue] = useState<number>(10);
    const croppedPicRef = useRef<AvatarEditor | null>(null);

    const queryClient = useQueryClient();

    async function postAvatar(formData: FormData) {
        return await apiClient.post(props.endpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async function setCanvas(): Promise<void> {
        if (croppedPicRef.current) {
            const canvas: HTMLCanvasElement = croppedPicRef.current.getImageScaledToCanvas();
            const blob = await new Promise<Blob>((resolve) => {
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        throw new Error('Failed to convert canvas to Blob.');
                    }
                });
            });

            const formData = new FormData();
            formData.append('file', blob);

            mutate(formData, {
                onSuccess: () => props.setModalOpen(false)
            })
        }
    }


    const { mutate, isLoading, isError } = useMutation('postAvatar', postAvatar, {
        onSuccess: () => {
            queryClient.invalidateQueries(props.invalidateQueryKey)
        }
    });

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSlideValue(newValue as number);
    };

    return (
        <Modal className='cropperModal' open={props.modalOpen}>
            <div className='cropperModal__content'>
                {props.newAvatarSrc ?
                    <>
                        <AvatarEditor
                            ref={croppedPicRef}
                            image={props.newAvatarSrc}
                            style={{ width: "100%", height: "100%" }}
                            border={40}
                            borderRadius={150}
                            color={[0, 0, 0, 0.72]}
                            scale={slideValue / 10}
                            rotate={0}
                        />

                        <Slider
                            min={10}
                            max={50}
                            sx={{
                                margin: "0 auto",
                                width: "80%",
                                color: "cyan"
                            }}
                            size="medium"
                            defaultValue={slideValue}
                            value={slideValue}
                            onChange={handleSliderChange}
                        />

                        <div className='cropperModal__buttonsBox'>
                            <button
                                className='buttonsBox__btn'
                                onClick={(e) => props.setModalOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className='buttonsBox__btn'
                                onClick={() => setCanvas()}
                            >
                                {isLoading ? <CircularProgress /> : "Save"}
                            </button>
                        </div>
                        {isError ? <p className='addPicture__error'>We couldn't load your picture, please try again.</p> : null}

                    </> : null
                }
            </div>
        </Modal>
    )
}

export default CropperModal