import styles from "./SearchResultsView.module.css";
import Contact from "../components/elements/Contact";
import { useSearchSupportContacts } from "../customHooks/useSearchSupportContacts";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetMyGroups } from "../customHooks/useGetMyGroups";
import { BasicGroupInfoType } from "../types/BasicGroupInfoType";
import { UserContactResponseType } from "../types/UserContactResponseType";
import React, { SetStateAction } from "react";
import { RecipientState } from "./DiscussionsOverview";
import { RecipientType } from "./types/RecipientType";
import { RecipientsDiscussionNavigation } from "./NavDiscussions";

interface SearchResultViewProps {
  searchValue: string;
  recipients: RecipientsDiscussionNavigation[] | undefined;
  setRecipients: React.Dispatch<
    SetStateAction<RecipientsDiscussionNavigation[] | undefined>
  >;
  setSearchVisible: React.Dispatch<SetStateAction<boolean>>;
  setFocusedDiscussion: React.Dispatch<SetStateAction<RecipientState>>;
}

function SearchResultsView(props: SearchResultViewProps) {
  const {
    data: contactsData,
    isLoading: isSearchingContacts,
    isError: isErrorSearchingContacts,
  } = useSearchSupportContacts({
    supportType: "supporting",
    searchPhrase: props.searchValue,
  });

  const {
    data: groupsData,
    isLoading: isSearchingGroups,
    isError: isErrorSearchingGroups,
  } = useGetMyGroups({
    searchPhrase: props.searchValue,
  });

  const handleNewDiscussion = (
    id: string,
    recipientType: RecipientType,
    avatar: string,
    name: string,
    userName: string
  ) => {
    const foundDiscussion = props.recipients?.find(
      (activity) => activity.id === id
    );

    if (!foundDiscussion) {
      props.setRecipients((activities?: RecipientsDiscussionNavigation[]) => [
        {
          id: id,
          recipientType: recipientType,
          avatar: avatar,
          name: name,
          userName: userName,
          unreadMessagesCount: 0,
        },
        ...(activities ?? []),
      ]);
    }
    props.setFocusedDiscussion({
      recipientId: id,
      recipientType: recipientType,
    });
    props.setSearchVisible(false);
  };

  return (
    <div className={styles.container}>
      {isSearchingContacts || isSearchingGroups ? (
        <CircularProgress />
      ) : isErrorSearchingContacts && isErrorSearchingGroups ? (
        <p>Something went wrong, please try again later.</p>
      ) : contactsData?.Supporting?.length === 0 &&
        groupsData?.groups.length === 0 ? (
        <p>No matches found</p>
      ) : (
        <div className={styles.searchResults}>
          {groupsData?.groups.map((group: BasicGroupInfoType) => (
            <Contact
              Name={group.name}
              Avatar={group.avatar}
              key={group.id}
              onClick={() =>
                handleNewDiscussion(
                  group.id,
                  RecipientType.Group,
                  group.avatar ? group.avatar : "",
                  group.name,
                  group.name
                )
              }
            />
          ))}
          {contactsData?.Supporting?.map((contact: UserContactResponseType) => (
            <Contact
              Name={contact.Name}
              UserName={contact.UserName}
              Avatar={contact.Avatar}
              key={contact.Id}
              onClick={() =>
                handleNewDiscussion(
                  contact.Id,
                  RecipientType.User,
                  contact.Avatar ? contact.Avatar : "",
                  contact.Name,
                  contact.UserName
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchResultsView;
