import './Comment.css';
import { ReactComponent as LittleHeartIcon } from '../../components/icons/LittleHeartIcon.svg';
import { ReactComponent as LittleHeartIconLiked } from '../../components/icons/LittleHeartIconLiked.svg';
import MiniAvatar from '../../components/elements/MiniAvatar';
import { getTimeAgo } from '../getTimeAgo';
import { useSendCommentReaction } from '../../customHooks/useSendCommentReaction';
import { DiscussionType } from '../../types/DiscussionType';

export interface CommentProps {
    id: string,
    content: string,
    senderAvatar: string,
    senderUserName: string,
    timestamp: string,
    isDeleted: boolean,
    reactionsCount: { [key: string]: number },
    currentUserReaction: null | string,
    isCurrentUserMessage: boolean,
    storyId: string,
    storyDateId: string,
    discussionType: DiscussionType,
    discussionRecipientId?: string,
}

function Comment(props: CommentProps) {

    const chosenReaction = '\u2764'; // when more reactions are added it will be modified to state

    const { mutate } = useSendCommentReaction({
        storyDateId: props.storyDateId,
        storyId: props.storyId,
        messagesHeaderId: props.id,
        discussionType: props.discussionType,
        discussionRecipientId: props.discussionRecipientId,
        messageId: props.id,
        chosenReaction: chosenReaction,
        newReaction: props.currentUserReaction === null ? chosenReaction : null,
        newReactionCount: props.currentUserReaction === null ? props.reactionsCount[chosenReaction] + 1 : props.reactionsCount[chosenReaction] - 1,
    });

    const handleCommentReaction = () => {
        mutate(props.currentUserReaction);
    }

    return (
        <>
            {props.isDeleted ?
                <div className='comment__deletedDiv'>
                    <div className='comment__avatarDiv'>
                        <MiniAvatar src={props.senderAvatar} />
                    </div>
                    <p className='comment__content comment__text comment__deleted'>
                        Comment has been deleted.
                    </p>
                </div> :
                <div className='comment'>
                    <div className='comment__container'>
                        <div className='comment__avatarDiv'>
                            <MiniAvatar src={props.senderAvatar} />
                        </div>
                        <div className='comment__content'>
                            <div className='comment__username'>{props.senderUserName}</div>
                            <div className='comment__time'>{getTimeAgo(props.timestamp)}</div>
                            <p className='comment__text'>{props.content}</p>
                        </div>
                    </div>
                    <div className='comment__likeButton' onClick={() => {
                        handleCommentReaction()
                    }
                    }>
                        {props.currentUserReaction ? <LittleHeartIconLiked /> : <LittleHeartIcon />}
                        <p>{props.reactionsCount[chosenReaction] !== 0 ? props.reactionsCount[chosenReaction] : null}</p>
                    </div>
                </div >
            }
        </>
    )
}

export default Comment