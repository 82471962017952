import { apiClient } from "../apiClient";
import { ContentResponse } from "./api/ContentResponse";
import { BaseCardApiType } from "./api/BaseCardApiType";
import { HeadlineStoryCardApiType } from "./api/HeadlineStoryCardApiType";
import { SummaryStoryCardApiType } from "./api/SummaryStoryCardApiType";
import { StockCardApiType } from "./api/StockCardApiType";
import { TwittCardApiType } from "./api/TwittCardApiType";
import { useQuery } from "react-query";
import ContentCard from "./ContentCard";
import { useParams } from "react-router-dom";
import HeaderFeed from "./HeaderFeed";
import useCalculateContentHeight from "../customHooks/useCalculateContentHeight";
import CircularProgress from "@mui/material/CircularProgress";
import cardsRenderer from "./cardsRenderer";
import "./Feed.css";
import globalStyles from "../components/global.module.css";

function SingleStory() {
  const { newsDate, newsId } = useParams();
  const contentHeight = useCalculateContentHeight();

  async function fetchStory() {
    const response = await apiClient.get(`/stories/${newsDate}/${newsId}`);
    const data = response.data.Story;
    const newContent: ContentResponse = {
      Categories: data.Categories,
      DateId: data.DateId,
      Language: data.Language,
      Id: data.Id,
      Favorite: data.Favorite,
      Likes: data.Likes,
      Source: data.Source,
      Cards: data.Cards.map((card: BaseCardApiType) => {
        if (card.Type === "HeadlineStoryCard") {
          return card as HeadlineStoryCardApiType;
        } else if (card.Type === "SummaryStoryCard") {
          return card as SummaryStoryCardApiType;
        } else if (card.Type === "StockCard") {
          return card as StockCardApiType;
        } else if (card.Type === "TwittCard") {
          return card as TwittCardApiType;
        } else {
          return null;
        }
      }).filter((card: BaseCardApiType) => card !== null),
    };
    return newContent;
  }

  const { data, isLoading, isError } = useQuery(
    `fetchStory${newsId}`,
    fetchStory
  );

  return (
    <div style={{ height: contentHeight }}>
      <HeaderFeed />
      {isLoading ? (
        <div className={globalStyles.centralMessage}>
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className={globalStyles.messageInfo}>
          <p>Error....</p>
        </div>
      ) : (
        //TODO add error handling, consider various cases (not found, etc)
        <>
          {data ? (
            <div
              className="content_container"
              key={`content-container-${data.Id}`}
            >
              <ContentCard
                cardId={data.Id}
                dateId={data.DateId}
                key={`content-card-${data.Id}`}
                favorite={data.Favorite}
                likes={data.Likes}
              >
                {cardsRenderer(data.Cards, data.Id, data.DateId, data.Source)}
              </ContentCard>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default SingleStory;
