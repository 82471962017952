import React from "react";
import { Box, Typography } from "@mui/material";
import SignInButton from "../auth/SignInButton";
import "./Welcome.css";

const Welcome: React.FC = () => {
  return (
    <div className="welcome_wrapper">
      <Box className="welcome__box">
        <Typography variant="h2" gutterBottom>
          Welcome to PaperPlanes!
        </Typography>
        <Typography variant="h5" mb={4} gutterBottom>
          Discover a world of news tailored to your interests.
        </Typography>
        <Typography variant="body1" mb={4}>
          Sign in and unlock the power of efficient, customized news updates.
        </Typography>
        <SignInButton />
      </Box>
    </div>
  );
};

export default Welcome;
