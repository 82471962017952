import './MiniAvatar.css'
import defaultProfilePicture from '../../profile/defaultProfilePicture.jpg'
import { CSSProperties } from 'react';

interface MiniAvatarDiscussionsProps {
    src?: string;
    onClick?: () => void;
    style?: CSSProperties;
    height?: string;
    className?: string;
}

function MiniAvatar(props: MiniAvatarDiscussionsProps) {

    const avatarStyle: CSSProperties = {
        ...props.style,
        height: props.height ? props.height : "30px",
        width: props.height ? props.height : "30px",
        borderRadius: props.height ? props.height : "30px"
    }
    return (
        <div className={'miniAvatar'} onClick={props.onClick} style={avatarStyle} >
            <img src={props.src ? props.src : defaultProfilePicture} alt="profilePicture" />
        </div >
    )
}

export default MiniAvatar