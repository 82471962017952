import { AxiosResponse } from "axios";
import { apiClient } from "../apiClient";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { DiscussionType } from "../types/DiscussionType";

export interface RecipientsData {
  id: string;
  recipientType: DiscussionType;
  avatar?: string;
  name?: string;
  userName: string;
  unreadMessagesCount: number;
}

export interface ActivityResponse {
  story: {
    dateId: string;
    language: string;
    id: string;
    headline: string;
    coverUrl: string;
    source: string;
  };
  newestMessage: {
    content: string;
    contentType: "text";
    id: string;
    isDeleted: boolean;
    isMyMessage: boolean;
    isRead: boolean;
    messageType: number;
    senderAvatar?: string;
    senderId: string;
    senderName: string;
    senderUserName: string;
    timestamp: string;
  };
  recipients: RecipientsData[];
}

export interface GetActivitiesResponse {
  activities: ActivityResponse[];
}

interface QueryContextProps {
  timestamp: string;
}

interface QueryResponseData {
  data: GetActivitiesResponse;
  notnewerthan: string;
}

interface UseGetDiscussionsActivitiesProps {
  discussionType: "public" | "private";
}

export function useGetDiscussionsActivities({
  discussionType,
}: UseGetDiscussionsActivitiesProps) {
  const endpoints: Record<"public" | "private", string> = {
    public: "discussions/activities",
    private: "discussions/private/activities",
  };

  const endpoint = endpoints[discussionType];

  const queryKeys: Record<"public" | "private", string> = {
    public: "getPublicActivities",
    private: "getDirectActivities",
  };

  const queryKey = queryKeys[discussionType];

  const fetchActivities = async (
    context: QueryFunctionContext<string, QueryContextProps | undefined>
  ): Promise<QueryResponseData> => {
    const response: AxiosResponse<GetActivitiesResponse> = await apiClient.get(
      endpoint,
      {
        params: {
          notnewerthan: context.pageParam?.timestamp,
        },
      }
    );

    const activities = response.data.activities;
    const notnewerthan =
      activities.length > 0
        ? activities[activities.length - 1].newestMessage.timestamp
        : "";

    return {
      data: response.data,
      notnewerthan,
    };
  };

  return useInfiniteQuery(queryKey, fetchActivities, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.notnewerthan) {
        return {
          timestamp: lastPage.notnewerthan,
        };
      } else {
        return undefined;
      }
    },
    refetchOnWindowFocus: false,
  });
}
