import "./Feed.css";
import { useState, useEffect } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { InView } from "react-intersection-observer";
import ContentCard from "./ContentCard";
import { apiClient } from "../apiClient";
import { AxiosResponse } from "axios";
import AnimationScreen from "../components/AnimationScreen";
import { ContentResponse } from "./api/ContentResponse";
import { HeadlineStoryCardApiType } from "./api/HeadlineStoryCardApiType";
import { BaseCardApiType } from "./api/BaseCardApiType";
import { SummaryStoryCardApiType } from "./api/SummaryStoryCardApiType";
import { StockCardApiType } from "./api/StockCardApiType";
import { TwittCardApiType } from "./api/TwittCardApiType";
import HeaderFeed from "./HeaderFeed";
import cardsRenderer from "./cardsRenderer";
import { ReactComponent as LogoIcon } from "../components/icons/PaperPlanes.svg";
import PullToRefresh from "react-simple-pull-to-refresh";

interface PageParam {
  LastReadNewsId?: string;
  DateId?: string;
}

export interface FeedResponseData {
  Stories: ContentResponse[];
  LastReadNewsId: string;
  DateId: string;
}

function Feed() {
  const [showEndingCard, setShowEndingCard] = useState<boolean>(false);
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  async function fetchContent(
    context: QueryFunctionContext<"content", PageParam>
  ): Promise<FeedResponseData | null> {
    const { pageParam } = context;
    const response: AxiosResponse<any> = await apiClient.get("/timeline", {
      params: {
        lastnewsid: pageParam?.LastReadNewsId,
        dateid: pageParam?.DateId,
        number: 5,
      },
    });
    const data = response.data;
    const content =
      data.Stories &&
      data.Stories.map((content: ContentResponse) => ({
        Categories: content.Categories,
        DateId: content.DateId,
        Language: content.Language,
        Id: content.Id,
        Favorite: content.Favorite,
        Likes: content.Likes,
        Source: content.Source,
        Cards: content.Cards.map((card: BaseCardApiType) => {
          if (card.Type === "HeadlineStoryCard") {
            return card as HeadlineStoryCardApiType;
          } else if (card.Type === "SummaryStoryCard") {
            return card as SummaryStoryCardApiType;
          } else if (card.Type === "StockCard") {
            return card as StockCardApiType;
          } else if (card.Type === "TwittCard") {
            return card as TwittCardApiType;
          } else {
            return null;
          }
        }).filter((card) => card !== null),
      }));
    return {
      Stories: content,
      LastReadNewsId: data.LastReadNewsId,
      DateId: data.DateId,
    };
  }

  const handleInViewChange = (inView: boolean, id: string) => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isError,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery("content", fetchContent, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage && lastPage.LastReadNewsId && lastPage.DateId) {
        return {
          LastReadNewsId: lastPage.LastReadNewsId,
          DateId: lastPage.DateId,
        };
      } else {
        return undefined; // No more pages
      }
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    const handleKeyScroll = (event: KeyboardEvent) => {
      const feedElement = document.querySelector(".feed");

      if (event.key === "ArrowUp") {
        event.preventDefault();
        feedElement?.scrollBy({ top: -500, behavior: "smooth" });
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        feedElement?.scrollBy({ top: 500, behavior: "smooth" });
      }
    };

    document.addEventListener("keydown", handleKeyScroll);

    return () => {
      document.removeEventListener("keydown", handleKeyScroll);
    };
  }, []);

  return (
    <>
      {isLoading && !isFetched ? (
        <AnimationScreen loadingText="LOADING..." showTopMenu={true} />
      ) : (
        <>
          <HeaderFeed />
          <div className="feed" key={`feed`}>
            {data?.pages?.map((page, pageIndex) =>
              page?.Stories?.map((contentItemInput, contentIndex) => {
                if (contentItemInput === null) return null;

                const contentItem = contentItemInput as ContentResponse;
                const cards = cardsRenderer(
                  contentItem.Cards,
                  contentItem.Id,
                  contentItem.DateId,
                  contentItem.Source
                );

                return (
                  <div
                    className="content_container"
                    key={`content-container-${contentItem.Id}`}
                  >
                    {contentIndex % 5 === 2 &&
                      !triggeringFetchIds.includes(contentItem.Id) && (
                        <InView
                          as="div"
                          onChange={(inView) =>
                            handleInViewChange(inView, contentItem.Id)
                          }
                        />
                      )}

                    <PullToRefresh
                      isPullable={pageIndex === 0 && contentIndex === 0}
                      onRefresh={refetch}
                      pullDownThreshold={50}
                      key={`pull-to-refresh-${contentItem.Id}`}
                    >
                      <ContentCard
                        cardId={contentItem.Id}
                        dateId={contentItem.DateId}
                        favorite={contentItem.Favorite}
                        likes={contentItem.Likes}
                        pageNumber={pageIndex}
                        key={`content-card-${contentItem.Id}`}
                      >
                        {cards}
                      </ContentCard>
                    </PullToRefresh>
                  </div>
                );
              })
            )}

            <InView
              className="inView"
              style={{ height: "10px", width: "100%" }}
              onChange={(inView) => {
                if (inView && hasNextPage && !isFetchingNextPage) {
                  fetchNextPage();
                } else if (!hasNextPage) {
                  setShowEndingCard(true);
                }
              }}
              threshold={0.1}
            ></InView>
            {isFetchingNextPage ? (
              <div className="loadingMore">Loading more content</div>
            ) : hasNextPage ? (
              <div className="loadingMore">Loading more...</div>
            ) : showEndingCard ? (
              <div className="content_container">
                <div className="endingCard" key={`endingCardKey`}>
                  <div className="endingCard__content">
                    <p>There is no more content available for you now</p>
                    <p>Please check out later</p>
                    <div>
                      <LogoIcon />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
export default Feed;
