import styles from "./SingleNotification.module.css";
import MiniAvatar from "../components/elements/MiniAvatar";
import { getTimeAgo } from "../discussions/getTimeAgo";

interface SingleNotificationProps {
  senderUserName: string;
  senderAvatar?: string;
  notificationInfo: string;
  timestamp: string;
  isRead: boolean;
}

function SingleNotification(props: SingleNotificationProps) {
  return (
    <div
      className={`${styles.notificationContainer} ${
        !props.isRead ? styles.notRead : ""
      }`}
    >
      <MiniAvatar height="40px" src={props.senderAvatar} />
      <div className={styles.infoContainer}>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{props.senderUserName}</div>
          <div className={styles.timeAgo}>{getTimeAgo(props.timestamp)}</div>
        </div>
        <div className={styles.notificationInfo}>{props.notificationInfo}</div>
      </div>
    </div>
  );
}

export default SingleNotification;
