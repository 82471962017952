import styles from "./DiscussionNavAvatar.module.css";
import globalStyles from "../../components/global.module.css";
import { forwardRef, useState, useEffect, useCallback } from "react";
import MiniAvatar from "../../components/elements/MiniAvatar";
import { useNewMessageEventContext } from "../context/NewMessageEventContext";
import { RecipientType } from "../types/RecipientType";
import { useQueryClient } from "react-query";
import { GetDiscussionResponse } from "../../customHooks/useGetDiscussion";
import { NewMessageNotificationType } from "../types/NewMessageNotificationType";

interface DiscussionNavAvatarProps {
  isFocusedDiscussion: boolean;
  handleClick: () => void;
  recipientAvatar?: string;
  recipientId: string;
  recipientType: RecipientType;
  storyId: string;
  storyDateId: string;
  unreadMessagesCount: number;
}

const DiscussionNavAvatar = forwardRef<
  HTMLDivElement,
  DiscussionNavAvatarProps
>(
  (
    {
      isFocusedDiscussion,
      handleClick,
      recipientAvatar,
      recipientId,
      recipientType,
      storyId,
      storyDateId,
      unreadMessagesCount,
    },
    ref
  ) => {
    const [pendingNotifications, setPendingNotifications] = useState<boolean>(
      unreadMessagesCount > 0
    );

    useEffect(() => {
      setPendingNotifications(unreadMessagesCount > 0);
    }, [unreadMessagesCount]);

    const { subscribe, unsubscribe } = useNewMessageEventContext();

    const queryClient = useQueryClient();

    const handleNewMessageNotification = useCallback(
      (message: NewMessageNotificationType) => {
        if (!isFocusedDiscussion) {
          setPendingNotifications(true);

          const queryKey = `getDiscussions${recipientType}${storyId}${recipientId}`;
          const discussionData: GetDiscussionResponse | undefined =
            queryClient.getQueryData(queryKey);

          if (discussionData) {
            const updatedDiscussionData: GetDiscussionResponse = {
              ...discussionData,
              discussion: {
                ...discussionData.discussion,
                displayMessages: [
                  {
                    id: message.id,
                    content: message.content,
                    senderAvatar: message.senderAvatar,
                    senderUserName: message.senderUserName,
                    timestamp: message.timestamp,
                    senderId: message.senderId,
                    senderName: message.senderName,
                    contentType: message.contentType,
                    messageType: message.messageType,
                    isDeleted: false,
                    deletedAt: null,
                    reactionsCount: { "\u2764": 0 },
                    currentUserReaction: null,
                    isCurrentUserMessage: false,
                    isRead: false,
                  },
                  ...discussionData.discussion.displayMessages,
                ],
              },
            };
            queryClient.setQueryData(queryKey, updatedDiscussionData);
          }
        } else setPendingNotifications(false);
      },
      [isFocusedDiscussion, queryClient, recipientId, recipientType, storyId]
    );

    useEffect(() => {
      const filter = {
        callerType: recipientType,
        callerId: recipientId,
        storyId: storyId,
        storyDateId: storyDateId,
      };

      subscribe(filter, handleNewMessageNotification);
      return () => {
        unsubscribe(handleNewMessageNotification);
      };
    }, [
      recipientId,
      recipientType,
      storyId,
      storyDateId,
      subscribe,
      unsubscribe,
      handleNewMessageNotification,
    ]);

    useEffect(() => {
      if (isFocusedDiscussion) {
        setPendingNotifications(false);
      }
    }, [isFocusedDiscussion]);

    return (
      <div className={styles.avatarBox} ref={ref}>
        <div
          className={`${styles.avatar} ${
            isFocusedDiscussion ? styles.avatarActive : ""
          }`}
          tabIndex={0}
          onClick={() => handleClick()}
        >
          <MiniAvatar src={recipientAvatar} />
        </div>
        {pendingNotifications && (
          <div className={globalStyles.avatarNotificationDot}></div>
        )}
      </div>
    );
  }
);

export default DiscussionNavAvatar;
